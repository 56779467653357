import React from "react";
import "./App.scss";
import { TrafficGame } from "./components/TrafficGame";
import { FirefightingGame } from "./components/FirefightingGame";
import { TrainGame } from "./components/TrainGame";
import { EscapeGame } from "./components/EscapeGame";
import { WBBGame } from "./components/WBBGame";

function App() {
    const html = '<label> Phone <input type="number" name="phone" onKeyDown="return false" /> </label>'

    return (
        <body className="links">
            {/* <TrafficGame />
            <FirefightingGame />
            <TrainGame />
            <EscapeGame />
            <WBBGame /> */}
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </body>
    );
}

export default App;